#search_output,
[data-ez-search-output] {
    position: absolute;
    min-width: 500px;
    background-color: white;
    margin: 0;
    margin-top: 40px;
    z-index: 100;
    padding: 10px;
    border-radius: 4px;
    -webkit-box-shadow: 0px 0px 13px 1px rgba(0, 0, 0, 0.56);
    -moz-box-shadow: 0px 0px 13px 1px rgba(0, 0, 0, 0.56);
    box-shadow: 0px 0px 13px 1px rgba(0, 0, 0, 0.56);
    overflow: auto;
    // max-height: 700px;
    ul {
        margin: 0px;
        li {
            list-style: none;
            padding: 5px;
             :not(:last-child) {
                margin-bottom: 5px;
            }
            .search_headline {
                border-bottom: 1px solid #D3D3D3;
                font-size: 1rem;
            }
            p {
                margin-bottom: 0;
                font-size: 0.8rem;
            }
            h3 {
                margin: 0;
            }
        }
        li.search_item {
            font-size: 16px;
            border-radius: 4px;
            .row a img {
                max-height: 100px;
            }
            .to_shopping_cart_button {
                // margin-top: 7px;
                // margin-bottom: 0px;
                padding: 5px 20px;
                border-radius: 5px;
                font-size: 20px;
                margin-top: 30px;
            }
            .productname {
                font-size: 0.9rem;
            }
            &:hover {
                background-color: #D3D3D3;
            }
        }
        li.search_item.product {
            i {
                font-size: 35px;
            }
            .productname {
                padding-left: 20px;
            }
        }
        li[data-search-item-active="true"] {
            background-color: lightgray;
        }
    }
    a,
    a:active,
    a:link,
    label {
        color: #EB7E2D;
        text-decoration: underline;
    }
}

#search_output::before,
[data-ez-search-output]:before {
    position: absolute;
    content: "";
    width: 0px;
    height: 0px;
    bottom: 100%;
    left: 20px;
    border-style: solid;
    border-width: 0 20px 20px 20px;
    border-color: transparent transparent white transparent;
    .information_item {
        .headline {
            .rating {
                color: #717070;
                font-style: italic;
            }
        }
    }
}